.sis-dr-rc-container {
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
}

.sis-dr-rc-titulo {
    font-size: var(--fonte-gigante);
    color: var(--cor-azul);
}

.sis-dr-rc-wrapper {
    width: 95%;
    max-height: 80%;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.sis-dr-rc-wrapper__div {
    background-color: transparent;
    border: .25rem solid var(--cor-azul);
    border-radius: .5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: .5rem;
}

.sis-dr-rc-wrapper__item {
    width: 100%;
    text-align: left;
    font-size: var(--fonte-normal);
    font-weight: bold;
    color: var(--cor-azul);
}

.sis-dr-rc-wrapper__titulo-pequeno {
    color: var(--cor-azul);
    font-size: var(--fonte-pequena);
}

.sis-dr-rc-wrapper__preco {
    color: var(--cor-azul);
    font-size: var(--fonte-grande);
    font-weight: bold;
}

.sis-dr-rc-wrapper__wrapper-botao {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: .5rem;
}

.sis-dr-rc-wrapper__quantidade {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.sis-dr-rc-wrapper__botao-quantidade {
    width: 2rem;
    height: 2rem;
    background-color: var(--cor-azul);
    border: none;
    border-radius: .5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    cursor: pointer;
}