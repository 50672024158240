.sis-iex-container {
    width: 100%;
    height: 100vh;
}

.sis-iex-main {
    width: 100%;
    padding: 1rem;
}

.sis-iex-wrapper {
    width: 100%;
    background-color: white;
    box-shadow: var(--sombra-card);
    padding: 1rem;
    border-radius: .5rem;
    display: flex;
    gap: 1rem;
}

.sis-iex-botao {
    cursor: pointer;
    font-size: var(--fonte-normal);
    padding: .5rem 1rem;
    margin: 1rem 0 0 1rem;
    font-weight: bold;
    background-color: var(--cor-laranja);
    border-radius: .25rem;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}



.sis-iex-main__tabela {
    width: 100%;
    padding: 1rem;
    margin-top: 1rem;
}

.sis-iex-main__thead {
    background-color: var(--cor-azul-escuro2);
    color: white;
}

.sis-iex-main__th {
    text-align: left;
    padding: .5rem 1rem;
    font-size: var(--fonte-normal);
}

.sis-iex-main__td {
    text-align: left;
    font-size: var(--fonte-normal);
    padding: .25rem 1rem;
    font-weight: normal;
}


.sis-iex-botao-detalhe {
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    font-size: var(--fonte-grande);
    background-color: var(--cor-laranja);
    border-radius: .25rem;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}
