.sis-pes-pe-container {
    width: 95%;
    padding: 1rem 0;
    background-color: white;
    box-shadow: var(--sombra-card);
    border-radius: .5rem;
    margin: 1rem auto;
}

.sis-pes-pe-header {
    width: 100%;
    height: 3rem;
    display: flex;
    align-items: center;
}

.sis-pes-pe-header__botao {
    width: 2.5rem;
    height: 2.5rem;
    cursor: pointer;
    font-size: var(--fonte-grande);
    background-color: var(--cor-laranja);
    border-radius: .25rem;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 1rem;
}

.sis-pes-pe-header__titulo {
    font-size: var(--fonte-grande);
    width: 200px;
    padding-left: 1rem;
}

.sis-pes-pe-wrapper {
    display: flex;
}

.sis-pes-pe-wrapper__div {
    margin-left: 1rem;
}

.sis-pes-pe-wrapper__titulo {
    font-size: var(--fonte-normal);
}

.sis-pes-pe-wrapper__descricao {
    font-size: var(--fonte-normal);
}
.sis-pes-pe-wrapper__botao{
    cursor: pointer;
    font-size: var(--fonte-normal);
    padding: .5rem 1rem;
    margin: 1rem 0 0 1rem;
    font-weight: bold;
    background-color: var(--cor-laranja);
    border-radius: .25rem;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sis-pes-pe-botao_excluir{
width: 2rem;
height: 2rem;
cursor: pointer;
font-size: var(--fonte-grande);
background-color: var(--cor-laranja);
border-radius: .25rem;
color: white;
display: flex;
justify-content: center;
align-items: center;}