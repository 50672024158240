.hs-header {
    width: 100%;
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    margin-top: 1rem;
}

.hs-header__logo {
    width: 7.5rem;
}

.hs-header__botao {
    width: 5rem;
    height: 5rem;
    font-size: 2.5rem;
    background-color: transparent;
    border: none;
    cursor: pointer;
}