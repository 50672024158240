.sis-ch-pg-container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
}

.sis-ch-pg-titulo {
    font-size: var(--fonte-gigante);
    color: var(--cor-azul);
}

.sis-ch-pg-wrapper {
    width: '90%';   
    align-self: 'center';
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.sis-ch-pg-wrapper__botao {
    width: 15rem;
    height: 15rem;
    background-color: transparent;
    border: .25rem solid var(--cor-azul);
    border-radius: .5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: var(--fonte-grande);
}

.sis-ch-pg-wrapper__selecionado {
    background-color: var(--cor-laranja);
    border: .25rem solid var(--cor-laranja);
    color: white;
}