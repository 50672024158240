body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --cor-azul: #2399ef;
  --cor-azul-hover: #2679b4;
  --cor-azul-claro: #D3F5FE;
  --cor-azul-muito-claro: #e8faff;
  --cor-azul-escuro: #103A3E;
  --cor-azul-escuro2: #364150;
  --cor-laranja: #D35835;
  --cor-vermelho: #d1301b;
  --cor-verde: green;

  --fonte-gigante: 1.25rem;
  --fonte-grande: 1.10rem;
  --fonte-normal: 1rem;
  --fonte-pequena: .90rem;
  --fonte-pequena: .75rem;

  --sombra-card: 0 0 1rem rgba(0, 0, 0, .3);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border-collapse: collapse;
}

tr {
  border-bottom: 1px solid lightgray;
}

a {
  text-decoration: none;
}
