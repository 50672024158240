.sis-dr-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.sis-dr-main {
    width: 95%;
    padding: 1rem;
    border-radius: .5rem;
    background-color: white;
    box-shadow: var(--sombra-card);
}

.sis-dr-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
}

.sis-dr-conteudo {
    height: 35rem;
}

.sis-dr-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
}

.sis-dr-header__botao {
    width: 2.5rem;
    height: 2.5rem;
    cursor: pointer;
    font-size: var(--fonte-grande);
    background-color: var(--cor-vermelho);
    border-radius: .25rem;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sis-dr-footer__proximo {
    border-radius: .5rem;
    background-color: var(--cor-laranja);
    padding: 1rem 2rem;
    color: white;
    font-weight: bold;
    font-size: var(--fonte-normal);
    cursor: pointer;
}

.sis-dr-footer__voltar {
    border-radius: .5rem;
    background-color: var(--cor-azul);
    padding: 1rem 2rem;
    color: white;
    font-weight: bold;
    font-size: var(--fonte-normal);
    cursor: pointer;
}

.sis-dr-conteudo__iniciar-container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sis-dr-conteudo__iniciar {
    border-radius: .5rem;
    background-color: var(--cor-laranja);
    padding: 1rem 2rem;
    color: white;
    font-weight: bold;
    font-size: var(--fonte-normal);
    cursor: pointer;
}