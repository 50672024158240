.hs-por-container {
    width: 100%;
    padding: 0 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.hs-por-img-container {
    width: 100%;
    border-radius: 2.5rem;
    overflow: hidden;
    object-fit: cover;
}

.hs-por-img-container__imagem {
    width: 100%;
}

.hs-por-titulo {
    font-size: 2rem;
    font-weight: normal;
}

.hs-por-lista__item {
    list-style: none;
    padding: 1.5rem 0;
    font-size: 1.75rem;
    border-bottom: .1rem solid gray;
}

.hs-por-lista__item:last-child {
    border-bottom: none;

}