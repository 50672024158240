.hs-sob-container {
    width: 100%;
    padding: 5rem 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.hs-sob-titulo {
    font-size: 1.5rem;
    color: var(--cor-azul-escuro);
    opacity: .5;
    text-align: center;
    font-weight: normal;
}

.hs-sob-subtitulo {
    font-size: 2rem;
    font-weight: normal;
    text-align: center;
}

.hs-sob-subitulo__span {
    text-decoration: underline;
    text-decoration-color: var(--cor-azul);
}

.hs-sob-card {
    width: 100%;
    padding: 2rem;
    border-radius: 2.5rem;
    background-color: white;
    box-shadow: 1rem 1rem 2.5rem rgba(0,0,0,.25);
    margin: 1rem 0;
}

.hs-sob-card__titulo {
    font-size: 1.75rem;
    font-weight: normal;
}

.hs-sob-card__subtitulo {
    font-size: 1.25rem;
    font-weight: normal;
    color: var(--cor-azul-escuro);
    opacity: .5;
    margin-top: .5rem;
}

.hs-sob-card__texto {
    text-decoration: none;
    font-size: 1.25rem;
    margin-top: 1rem;
}