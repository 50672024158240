.hs-sli-container {
    width: 100%;
}

.hs-sli-bloco1 {
    width: 100%;
    flex-direction: column;
    display: flex;
    gap: 1rem;
    padding: 3rem 1.5rem;
}

.hs-sli-titulo {
    font-size: 2.5rem;
    font-weight: lighter;
}

.hs-sli-titulo__span {
    font-weight: normal;
}

.hs-sli-subtitulo {
    font-weight: normal;
    color: var(--cor-azul-escuro);
    opacity: .5;
}

.hs-sli-botao {
    width: 15rem;
    border-radius: 50rem;
    background-color: var(--cor-azul);
    color: var(--cor-azul-escuro);
    font-size: var(--fonte-grande);
    padding: 1rem 2rem;
    margin-top: 1rem;
    border: none;
}

.hs-sli-bloco2 {
    width: 100%;
    display: flex;
    justify-content: center;
}

.hs-sli-imagem {
    width: 90%;
}