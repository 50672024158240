.sis-ch-dp-container {
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
}

.sis-ch-dp-titulo {
    font-size: var(--fonte-gigante);
    color: var(--cor-azul);
}

.sis-ch-dp-wrapper {
    width: 80%;
    max-height: 80%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.sis-ch-dp-input-wrapper {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}

.sis-ch-dp-label {
    font-size: var(--fonte-nomal);
    font-weight: bold;
    color: var(--cor-azul);
}

.sis-ch-dp-input {
    width: 100%;
    background-color: transparent;
    padding: 1rem 1rem;
    border: .2rem solid var(--cor-azul);
    border-radius: .5rem;
    font-size: var(--fonte-nomal);
}

.sis-ch-dp-input--mobile {
    width: 85%;
    background-color: transparent;
    padding: 1rem 1rem;
    border: .2rem solid var(--cor-azul);
    border-radius: .5rem;
    font-size: var(--fonte-nomal);
}