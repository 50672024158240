.sis-dr-de-container {
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
}

.sis-dr-de-titulo {
    font-size: var(--fonte-gigante);
    color: var(--cor-azul);
}

.sis-dr-de-wrapper {
    width: 80%;
    max-height: 100%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.sis-dr-de-input-wrapper {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}

.sis-dr-de-label {
    font-size: var(--fonte-nomal);
    font-weight: bold;
    color: var(--cor-azul);
}

.sis-dr-de-input {
    width: 100%;
    background-color: transparent;
    padding: 1rem 1rem;
    border: .2rem solid var(--cor-azul);
    border-radius: .5rem;
    font-size: var(--fonte-nomal);
}

.sis-dr-de-input-bloco {
    width: 49%;
}

.sis-dr-de-input-wrapper__botao {
    width: 49%;
    height: calc(100% - 1.25rem);
    align-self: flex-end;
    background-color: var(--cor-azul);
    font-size: var(--fonte-nomal);
    font-weight: bold;
    border-radius: .5rem;
    color: white;
    cursor: pointer;
}