.sis-fi-main {
    width: 97.5%;
    margin: 0 auto;
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
}

.sis-fi-main__container {
    width: 100%;
    padding: 1rem 0;
    background-color: white;
    border-radius: .5rem;
    box-shadow: var(--sombra-card);
}

.sis-fi-main__titulo {
    font-size: var(--fonte-grande);
    margin: 0 1rem;
}

.sis-fi-main__tabela {
    width: 100%;
    padding: 1rem;
    margin-top: 1rem;
}

.sis-fi-main__thead {
    background-color: var(--cor-azul-escuro2);
    color: white;
}

.sis-fi-main__th {
    text-align: left;
    padding: .5rem 1rem;
    font-size: var(--fonte-normal);
}

.sis-fi-main__td {
    text-align: left;
    font-size: var(--fonte-normal);
    padding: .25rem 1rem;
    font-weight: normal;
}

.sis-fi-main__botao {
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    font-size: var(--fonte-grande);
    background-color: var(--cor-laranja);
    border-radius: .25rem;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sis-fi-main__botao-excel {
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    font-size: var(--fonte-grande);
    background-color: var(--cor-verde);
    border-radius: .25rem;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
}

.sis-fi-main__botao-busca {
    cursor: pointer;
    font-size: var(--fonte-normal);
    font-weight: bold;
    background-color: var(--cor-laranja);
    border-radius: .25rem;
    padding: .5rem 1rem;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sis-fi-main__wrapper {
    width: 95%;
    margin: 1rem auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 1rem;
}

.sis-fi-main__resumo {
    width: 95%;
    margin: 1rem auto;
    display: flex;
    gap: 1rem;
}

.sis-fi-main__select {
    width: 16rem;
    cursor: pointer;
    padding: .25rem;
    font-size: var(--fonte-media)
}

.sis-fi-main__input-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
}

.sis-fi-main__input {
    width: 6.8rem;
    padding: .25rem;
    cursor: pointer;
}

.sis-fi-main__botao-wrapper {
    display: flex;
    justify-content: right;
}

.sis-fi-main__tabela-botao-wrapper {
    display: flex;
    gap: .5rem;
}