.c-loading {
    width: 1.5rem;
    height: 1.5rem;
    margin: 0 auto;
    border-radius: 50rem;
    border: .3rem solid transparent;
    border-left-color: var(--cor-laranja);
    animation: loading;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes loading {
    0% {
        transform: rotate(0);
    } 

    100% {
        transform: rotate(360deg);
    }
}