.sis-dr-ep-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sis-dr-ep-titulo {
    font-size: var(--fonte-gigante);
    color: var(--cor-azul);
}

.sis-dr-ep-wrapper {
    width: 95%;
    max-height: 80%;
    overflow: scroll;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 2rem;
    margin: 0 auto;
}

.sis-dr-ep-wrapper__botao {
    width: 12rem;
    border: none;
    border-radius: .5rem;
    cursor: pointer;
}

.sis-dr-ep-wrapper__imagem {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.sis-dr-ep-wrapper__selecionado {
    border: .25rem solid var(--cor-laranja);
}