.sis-docs-doc-container {
    width: 95%;
    padding: 1rem 0;
    background-color: white;
    box-shadow: var(--sombra-card);
    border-radius: .5rem;
    margin: 1rem auto;
}

.sis-docs-doc-header {
    width: 100%;
    height: 3rem;
    display: flex;
    align-items: center;
}

.sis-docs-doc-header__botao {
    width: 2.5rem;
    height: 2.5rem;
    cursor: pointer;
    font-size: var(--fonte-grande);
    background-color: var(--cor-laranja);
    border-radius: .25rem;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 1rem;
}

.sis-docs-doc-header__titulo {
    font-size: var(--fonte-grande);
}

.sis-docs-doc-wrapper {
    display: flex;
}

.sis-docs-doc-wrapper__div {
    margin-left: 1rem;
}

.sis-docs-doc-wrapper__titulo {
    font-size: var(--fonte-normal);
}

.sis-docs-doc-wrapper__descricao {
    font-size: var(--fonte-normal);
}

.sis-docs-doc-tabela {
    width: 100%;
    padding: 1rem;
    margin-top: 1rem;
}

.sis-docs-doc-tabela__thead {
    background-color: var(--cor-azul-escuro2);
    color: white;
}

.sis-docs-doc-tabela__th {
    text-align: left;
    padding: .5rem 1rem;
    font-size: var(--fonte-normal);
}

.sis-docs-doc-botao-detalhe {
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    font-size: var(--fonte-grande);
    background-color: var(--cor-laranja);
    border-radius: .25rem;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sis-docs-div2__td {
    text-align: left;
    font-size: var(--fonte-normal);
    padding: .25rem 1rem;
    font-weight: normal;
}