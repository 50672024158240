.sis-ch-da-container {
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
}

.sis-ch-da-titulo {
    font-size: var(--fonte-gigante);
    color: var(--cor-azul);
}

.sis-ch-da-wrapper {
    width: 100%;
    max-height: 80%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.sis-ch-da-input-wrapper {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}

.sis-ch-da-input-wrapper--mobile {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}

.sis-ch-da-label {
    font-size: var(--fonte-nomal);
    font-weight: bold;
    color: var(--cor-azul);
}

.sis-ch-da-input {
    width: 100%;
    background-color: transparent;
    padding: 1rem 1rem;
    border: .2rem solid var(--cor-azul);
    border-radius: .5rem;
    font-size: var(--fonte-grande);
}