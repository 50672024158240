.sis-dr-ts-container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
}

.sis-dr-ts-titulo {
    font-size: var(--fonte-gigante);
    color: var(--cor-azul);
}

.sis-dr-ts-wrapper {
    display: flex;
    gap: 5rem;
}

.sis-dr-ts-wrapper__botao {
    width: 15rem;
    height: 15rem;
    background-color: transparent;
    border: .25rem solid var(--cor-azul);
    border-radius: .5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: var(--fonte-grande);
}

.sis-dr-ts-wrapper__selecionado {
    color: white;
    font-size: var(--fonte-grande);
    font-weight: bold;
    background-color: var(--cor-laranja);
    border: .25rem solid var(--cor-laranja);
}