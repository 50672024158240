.sis-ch-ef-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.sis-ch-ef-titulo {
    font-size: var(--fonte-gigante);
    color: var(--cor-azul);
}

.sis-ch-ef-wrapper {
    width: 95%;
    max-height: 80%;
    overflow: scroll;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 2rem;
    margin: 0 auto;
}

.sis-ch-ef-wrapper--mobile {
    width: 95%;
    max-height: 80%;
    overflow: scroll;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 5rem;
    margin: 0 auto;
}

.sis-ch-ef-wrapper__botao {
    width: 12rem;
    border: none;
    border-radius: .5rem;
    cursor: pointer;
    background-color: transparent;
}

.sis-ch-ef-wrapper__botao--mobile {
    width: 100%;
    border: none;
    border-radius: .5rem;
    cursor: pointer;
    background-color: transparent;
}

.sis-ch-ef-wrapper__imagem {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.sis-ch-ef-wrapper__imagem--mobile {
    width: 70%;
    height: 100%;
    object-fit: contain;
}

.sis-ch-ef-wrapper__selecionado {
    border: .25rem solid var(--cor-laranja);
}