.sis-exe-container {
    width: 100%;
    height: 100vh;
}

.sis-docs-div2__botao-atualizar {
    cursor: pointer;
    font-size: var(--fonte-normal);
    padding: .5rem 1rem;
    margin: 1rem 0 0 1rem;
    font-weight: bold;
    background-color: var(--cor-laranja);
    border-radius: .25rem;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}