.sis-docs-main {
    width: 97.5%;
    margin: 0 auto;
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
}

.sis-docs-div1 {
    width: 20rem;
}

.sis-docs-div1__container {
    width: 100%;
    padding: 1rem 0;
    background-color: white;
    border-radius: .5rem;
    box-shadow: var(--sombra-card);
}

.sis-docs-div1__wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.sis-docs-div1__titulo-departamento {
    font-size: var(--fonte-normal);
}

.sis-docs-div1__botao-wrapper {
    width: 95%;
    display: flex;
    justify-content: center;
}

.sis-docs-div1__processo {
    width: 100%;
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    padding: 1rem 2rem;
    cursor: pointer;
    border-radius: .25rem;
    margin-bottom: 1rem;
    font-size: var(--fonte-normal);
}

.sis-docs-div1__selecionado {
    background-color: var(--cor-laranja);
    color: white;
    font-weight: bold;
}

.sis-docs-div2 {
    width: calc(100% - 21rem);
}

.sis-docs-div2__wrapper {
    width: 100%;
    padding: 1rem 0;
    background-color: white;
    border-radius: .25rem;
    box-shadow: var(--sombra-card);
}

.sis-docs-div2__titulo {
    font-size: var(--fonte-grande);
    margin: 0 1rem;
}

.sis-docs-div2__tabela {
    width: 100%;
    padding: 1rem;
    margin-top: 1rem;
}

.sis-docs-div2__thead {
    background-color: var(--cor-azul-escuro2);
    color: white;
}

.sis-docs-div2__th {
    text-align: left;
    padding: .5rem 1rem;
    font-size: var(--fonte-normal);
}

.sis-docs-div2__td {
    text-align: left;
    font-size: var(--fonte-normal);
    padding: .25rem 1rem;
    font-weight: normal;
}

.sis-docs-div2__botao {
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    font-size: var(--fonte-grande);
    background-color: var(--cor-laranja);
    border-radius: .25rem;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}