.sis-pes-main {
    width: 97.5%;
    margin: 0 auto;
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
}

.sis-pes-div1 {
    width: 20rem;
}

.sis-pes-div1__wrapper {
    width: 100%;
    padding: 1rem 0;
    background-color: white;
    border-radius: .5rem;
    box-shadow: var(--sombra-card);
}

.sis-pes-div1__perfil {
    width: 95%;
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    padding: 1rem 2rem;
    cursor: pointer;
    border-radius: .25rem;
    margin: 0 auto 1rem auto;
    font-size: var(--fonte-normal);
}

.sis-pes-div1__selecionado {
    background-color: var(--cor-laranja);
    color: white;
    font-weight: bold;
}

.sis-pes-div2 {
    width: calc(100% - 21rem);
}

.sis-pes-div2__wrapper {
    width: 100%;
    padding: 1rem 0;
    background-color: white;
    border-radius: .5rem;
    box-shadow: var(--sombra-card);
}

.sis-pes-div2__titulo {
    font-size: var(--fonte-grande);
    margin: 0 1rem ;
}

.sis-pes-div2__tabela {
    width: 100%;
    padding: 1rem;
    margin-top: 1rem;
}

.sis-pes-div2__thead {
    background-color: var(--cor-azul-escuro2);
    color: white;
}

.sis-pes-div2__th {
    text-align: left;
    padding: .5rem 1rem;
    font-size: var(--fonte-normal);
}

.sis-pes-div2__td {
    text-align: left;
    font-size: var(--fonte-normal);
    padding: .25rem 1rem;
    font-weight: normal;
}

.sis-pes-div2__botao {
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    font-size: var(--fonte-grande);
    background-color: var(--cor-laranja);
    border-radius: .25rem;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sis-pes-form {
    margin: 1rem;
    display: flex;
    gap: 1rem;
}

.sis-pes-form__input {
    width: 15rem;
    padding: .5rem .25rem;
    border-radius: .25rem;
    border:none;
    background-color: var(--cor-azul-muito-claro);
    border: .15rem solid rgb(35, 153, 239, .25);
    outline: none;
    font-size: var(--fonte-normal);
    box-sizing: border-box;
}

.sis-pes-form__botao {
    cursor: pointer;
    font-size: var(--fonte-normal);
    padding: .5rem 1rem;
    font-weight: bold;
    background-color: var(--cor-laranja);
    border-radius: .25rem;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}