.log-container {
    width: 100%;
    height: 100vh;
    background-color: var(--cor-azul-escuro2);
    z-index: 9999;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
}

.log-wrapper {
    width: 35rem;
    height: 25rem;
    background-color: white;
    border-radius: .5rem;
    box-shadow: var(--sombra-card);
}

.log-wrapper__titulo {
    font-size: var(--fonte-gigante);
    font-weight: bold;
    color: var(--cor-azul);
    margin-top: 1rem;
    text-align: center;
}

.log-blocos {
    display:flex;
    width: 100%;
    height: 20rem;
}

.log-bloco1 {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.log-wrapper__logo {
    width: 10rem;
}

.log-wrapper__logo-botao {
    border: none;
    cursor: pointer;
    margin-bottom: 2rem;
    background-color: transparent;
}

.log-bloco2 {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.log-wrapper__form {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.log-wrapper__div {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.log-wrapper__label {
    font-weight: bold;
}

.log-wrapper__input {
    width: 100%;
    padding: .5rem .25rem;
    border-radius: .25rem;
    border:none;
    background-color: var(--cor-azul-muito-claro);
    border: .15rem solid rgb(35, 153, 239, .25);
    outline: none;
    font-size: var(--fonte-normal);
    box-sizing: border-box;
}

.log-wrapper__input:focus {
    border: .15rem solid var(--cor-azul);
}

.log-wrapper__botao-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
}

.log-wrapper__botao {
    width: 100%;
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    padding: .5rem 1rem;
    cursor: pointer;
    border-radius: .5rem;
    margin-bottom: 1rem;
    font-size: var(--fonte-normal);
    background-color: var(--cor-laranja);
    color: white;
    font-weight: bold;
    margin-top: 1rem;
}

.log-wrapper_botao_recuperarSenha{
    border: none;
    background-color: transparent;
    color: firebrick;
    cursor: pointer;
    font-size: var(--fonte-normal);
}