.hs-con-container {
    width: 100%;
    padding: 1.5rem;
}

.hs-con-card {
    width: 100%;
    background-color: var(--cor-azul-escuro);
    padding: 1rem;
    border-radius: 2.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.hs-con-card__titulo {
    font-size: 1.75rem;
    color: var(--cor-azul);
    text-align: center;
    font-weight: normal;
}

.hs-con-card__subtitulo {
    font-size: 1.5rem;
    color: var(--cor-azul-claro);
    opacity: .8;
    text-align: center;
    font-weight: normal;
}

.hs-con-card__botao {
    width: 15rem;
    border-radius: 50rem;
    background-color: var(--cor-azul);
    color: var(--cor-azul-escuro);
    font-size: var(--fonte-grande);
    padding: 1rem 2rem;
    margin: 1rem auto;
    border: none;
}