.sis-ch-de-container {
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.sis-ch-de-titulo {
    font-size: var(--fonte-gigante);
    color: var(--cor-azul);
}

.sis-ch-de-wrapper {
    width: 100%;
    max-height: 100%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.sis-ch-de-wrapper--mobile {
    width: 85%;
    max-height: 80%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.sis-ch-de-input-wrapper {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}

.sis-ch-de-input-wrapper--mobile {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 1rem;
}

.sis-ch-de-label {
    font-size: var(--fonte-nomal);
    font-weight: bold;
    color: var(--cor-azul);
}

.sis-ch-de-input {
    width: 100%;
    background-color: transparent;
    padding: 1rem 1rem;
    border: .2rem solid var(--cor-azul);
    border-radius: .5rem;
    font-size: var(--fonte-nomal);
}

.sis-ch-de-input-bloco {
    width: 49%;
}

.sis-ch-de-input-bloco--mobile {
    width: 100%;
}

.sis-ch-de-input-wrapper__botao {
    width: 100%;
    height: calc(100% - 1.25rem);
    align-self: flex-end;
    background-color: var(--cor-azul);
    font-size: var(--fonte-nomal);
    font-weight: bold;
    border-radius: .5rem;
    color: white;
    cursor: pointer;
}

.sis-ch-de-input-wrapper__botao--mobile {
    width: 100%;
    height: 4rem;
    background-color: var(--cor-azul);
    font-size: var(--fonte-nomal);
    font-weight: bold;
    border-radius: .5rem;
    color: white;
    cursor: pointer;
    align-self: center;
}