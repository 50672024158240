.sis-header {
    width: 100%;
    height: 4rem;
    background-color: white;
    border-radius: .5rem;
    box-shadow: var(--sombra-card);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sis-header__logo {
    height: 3rem;
    object-fit: contain;
    margin-left: 2rem;
}

.sis-header__botao {
    width: 2.5rem;
    height: 2.5rem;
    cursor: pointer;
    font-size: var(--fonte-grande);
    border-radius: .25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 2rem;
    border: none;
    color: var(--cor-laranja)
}

.sis-header__wrapper1 {
    width: 20%;
}
.sis-header__wrapper2 {
    width: 20%;
    display: flex;
    justify-content: right;
}

.sis-header__nav {
    width: 60%;
    display: flex;
    justify-content: center;
    gap: 2rem;
}

.sis-header__botao-nav {
    border: none;
    background-color: transparent;
    font-size: var(--fonte-normal);
    color: var(--cor-azul-escuro2);
    font-weight: bold;
    cursor: pointer;
}

.sis-header__selecionado {
    font-weight: bold;
    color: var(--cor-laranja);
}