.sis-iex-pes-container {
    width: 95%;
    padding: 1rem 0;
    background-color: white;
    box-shadow: var(--sombra-card);
    border-radius: .5rem;
    margin: 1rem auto;
}

.sis-iex-pes-header {
    width: 100%;
    height: 3rem;
    display: flex;
    align-items: center;
}

.sis-iex-pes-header__botao {
    width: 2.5rem;
    height: 2.5rem;
    cursor: pointer;
    font-size: var(--fonte-grande);
    background-color: var(--cor-laranja);
    border-radius: .25rem;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 1rem;
}

.sis-iex-pes-header__titulo {
    font-size: var(--fonte-grande);
    width: 200px;
    padding-left: 1rem;
}

.sis-iex-pes-wrapper {
    display: flex;
}

.sis-iex-pes-wrapper__div {
    margin-left: 1rem;
}

.sis-iex-pes-wrapper__titulo {
    font-size: var(--fonte-normal);
}

.sis-iex-pes-wrapper__descricao {
    font-size: var(--fonte-normal);
}
.sis-iex-pes-wrapper__botao{
    cursor: pointer;
    font-size: var(--fonte-normal);
    padding: .5rem 1rem;
    margin: 1rem 0 0 1rem;
    font-weight: bold;
    background-color: var(--cor-laranja);
    border-radius: .25rem;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}


.sis-iex-pes-wrapper-container_checkBox{
    padding: 1rem;
}

.sis-iex-pes-wrapper-cointaienr_labelCheckBox{
    margin-right: 1rem;
    margin-left: 0.5rem;
}


.sis-iex-pes-wrapper_selecione{
    padding: 1rem;
    display: flex;
}
.sis-iex-pes-botao_excluir{
width: 2rem;
height: 2rem;
cursor: pointer;
font-size: var(--fonte-grande);
background-color: var(--cor-laranja);
border-radius: .25rem;
color: white;
display: flex;
justify-content: center;
align-items: center;}


.sis-iex-pes-main__tabela {
    width: 100%;
    padding: 1rem;
    margin-top: 1rem;
}

.sis-iex-pes-main__thead {
    background-color: var(--cor-azul-escuro2);
    color: white;
}

.sis-iex-pes-main__th {
    text-align: left;
    padding: .5rem 1rem;
    font-size: var(--fonte-normal);
}

.sis-iex-pes-main__td {
    text-align: left;
    font-size: var(--fonte-normal);
    padding: .25rem 1rem;
    font-weight: normal;
}


.sis-iex-pes-botao-detalhe {
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    font-size: var(--fonte-grande);
    background-color: var(--cor-laranja);
    border-radius: .25rem;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}
