.sis-ch-ec-container {
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
}

.sis-ch-ec-titulo {
    font-size: var(--fonte-gigante);
    color: var(--cor-azul);
}

.sis-ch-ec-wrapper {
    width: 95%;
    max-height: 80%;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.sis-ch-ec-wrapper__botao {
    background-color: transparent;
    border: .25rem solid var(--cor-azul);
    border-radius: .5rem;
    cursor: pointer;
    display: flex;
    padding: 1rem;
}

.sis-ch-ec-wrapper__selecionado {
    color: var(--cor-laranja);
    font-size: var(--fonte-grande);
    font-weight: bold;
    border: .25rem solid var(--cor-laranja);
}

.sis-ch-ec-wrapper__titulo {
    width: 100%;
    text-align: left;
    font-size: var(--fonte-grande);
    font-weight: bold;
    color: var(--cor-azul);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sis-ch-ec-wrapper__preco {
    font-size: var(--fonte-grande);
}