.sis-ch-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.sis-ch-main {
    width: 100%;
    padding: 1rem;
    background-color: white;
}

.sis-ch-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
}

.sis-ch-conteudo {
    height: 30rem;
}

.sis-ch-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sis-ch-header__botao {
    width: 2.5rem;
    height: 2.5rem;
    cursor: pointer;
    font-size: var(--fonte-grande);
    background-color: var(--cor-vermelho);
    border-radius: .25rem;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sis-ch-footer__proximo {
    border-radius: .5rem;
    background-color: var(--cor-laranja);
    padding: 1rem 2rem;
    color: white;
    font-weight: bold;
    font-size: var(--fonte-normal);
    cursor: pointer;
}

.sis-ch-footer__voltar {
    border-radius: .5rem;
    background-color: var(--cor-azul);
    padding: 1rem 2rem;
    color: white;
    font-weight: bold;
    font-size: var(--fonte-normal);
    cursor: pointer;
}

.sis-ch-conteudo__iniciar-container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sis-ch-conteudo__iniciar {
    border-radius: .5rem;
    background-color: var(--cor-laranja);
    padding: 1rem 2rem;
    color: white;
    font-weight: bold;
    font-size: var(--fonte-normal);
    cursor: pointer;
}